import { Map } from 'immutable';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useFormContext, useFormState, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { Box, NewTypography, tokens, Tooltip, Typography } from '@unitoio/mosaic';

import { getFeatureFlagValue, getProviderIdentityById } from '~/reducers';
import { Href } from '~/components/Href/Href';
import { ProviderAvatar } from '~/components/ProviderAvatar/ProviderAvatar';
import * as featureTypes from '~/consts/features';
import * as linkTypes from '~/consts/link';
import * as routes from '~/consts/routes';
import { useGetItemTypes } from '~/containers/FlowBuilder/hooks/useGetItemTypes';
import { FlowBuilderErrorContext } from '~/contexts';

import { useContainerLinkTrackEvent } from '../../hooks/useContainerLinkTrackEvent';
import { PAGES, useGetLabelAndStatusFromAnomalies } from '../../hooks/useGetAnomalies';
import { useGetContainerErrors } from '../../hooks/useGetContainerErrors';
import { useGetContainers } from '../../hooks/useGetContainers';
import { useGetContainerTypes } from '../../hooks/useGetContainerTypes';
import { useGetProviderExtraStep } from '../../hooks/useGetProviderExtraStep';
import { useHasMissingExtraStepAction } from '../../hooks/useHasMissingExtraStepAction';
import { useIsFlowDraft } from '../../hooks/useIsFlowDraft';
import { GuideStep } from './GuideStep';
import { GuideStepTitleWithTime } from './GuideStepTitleWithTime';

import { useShouldShowWorkItemStepMissing } from '~/containers/FlowBuilder/hooks/useIsWorkItemStepMissingConfig';
import ToolSelectionIcon from '../../images/steps_icons/tool_selection.svg';
import { GuideStepWorkItemLabel } from './GuideStepWorkItemLabel';

const FakeHref = styled.a`
  &:hover {
    text-decoration: none;
  }

  cursor: default;
`;

const getActionLabel = (status) => {
  if (status === 'edit') {
    return 'Edit tools';
  }

  // in some cases, for warningStatus, we render the label "review"
  if (status === 'error' || status === 'review') {
    return 'Review';
  }
  return 'Continue';
};

function useGetProviderIdentities() {
  const { getValues } = useFormContext();
  const providerIdentityIdA = getValues('A.providerIdentityId');
  const providerIdentityIdB = getValues('B.providerIdentityId');
  const providerIdentityA = useSelector((state) => getProviderIdentityById(state, providerIdentityIdA));
  const providerIdentityB = useSelector((state) => getProviderIdentityById(state, providerIdentityIdB));
  return [providerIdentityA, providerIdentityB];
}

const StepIcon = ({ providerName, providerIdentity }) =>
  providerName ? (
    <ProviderAvatar
      providerName={providerName}
      username={providerIdentity.get('profileDisplayName')}
      image={providerIdentity.getIn(['profileAvatars', 0])}
    />
  ) : (
    <Box p={['calc(1rem - 1px)']} borderColor={tokens.colors.content.neutral.n10} borderRadius={tokens.spacing.s3} />
  );

StepIcon.propTypes = {
  providerName: PropTypes.string,
  providerIdentity: PropTypes.instanceOf(Map).isRequired,
};

export const getTruncatedErrorMessage = (errorMessage) => {
  if (!errorMessage) {
    return null;
  }
  return (
    <NewTypography.Paragraph
      style={{ fontSize: tokens.fontSize.f6, maxWidth: '700px', marginBottom: 0, marginRight: tokens.spacing.s4 }}
      ellipsis={{ rows: 1, expandable: true, symbol: 'more' }}
    >
      {errorMessage}
    </NewTypography.Paragraph>
  );
};

export const getClickableContainerName = ({
  containerName,
  containerUrl,
  isContainerSelected,
  providerName,
  trackEvent,
  status,
}) => {
  const HrefElement = isContainerSelected ? Href : FakeHref;

  if (!containerName) {
    return null;
  }
  return containerUrl ? (
    <Tooltip content={`Open ${containerName} in a new tab`} forceHide={!isContainerSelected} placement="top">
      <HrefElement onClick={() => trackEvent(providerName)} href={containerUrl} disabled={!isContainerSelected}>
        <NewTypography.Text
          style={{
            fontSize: tokens.fontSize.f6,
            wordBreak: 'none',
            color: status === 'locked' ? tokens.colors.content.neutral.n20 : tokens.colors.content.neutral.n40,
          }}
        >
          {containerName}
        </NewTypography.Text>
      </HrefElement>
    </Tooltip>
  ) : (
    <HrefElement onClick={() => trackEvent(providerName)} href={containerUrl} disabled={!isContainerSelected}>
      <NewTypography.Text style={{ fontSize: tokens.fontSize.f6 }}>{containerName}</NewTypography.Text>
    </HrefElement>
  );
};

const getStepStatus = ({
  showStepInErrorState,
  isDraft,
  containerNameA,
  containerNameB,
  shouldRenderLegacyAnomalies,
  isLiteGuide,
  hasContainerErrors,
  statusFromAnomalies,
}) => {
  let status = showStepInErrorState ? 'error' : 'edit';

  if (isDraft && !showStepInErrorState) {
    status = !containerNameA || !containerNameB ? 'current' : 'done';
  }

  // when revamp is disabled, we use the legacy anomalies to determine the status
  if (shouldRenderLegacyAnomalies) {
    status = statusFromAnomalies;
  }
  // otherwise, we use the container errors to determine the status as error
  if (hasContainerErrors) {
    status = 'error';
  }

  const isStepLocked = isLiteGuide && !isDraft;

  if (isStepLocked) {
    status = 'locked';
  }

  return status;
};

export const GuideStepWorkItems = () => {
  const { linkId } = useParams();
  const pageName = useContext(FlowBuilderErrorContext);
  const trackEvent = useContainerLinkTrackEvent();

  const { errors } = useFormState();
  const [
    providerNameA,
    providerNameB,
    containerIdA,
    containerIdB,
    containerDisplayNameA,
    containerDisplayNameB,
    containerAUrlFromFlow,
    containerBUrlFromFlow,
    linkKind,
  ] = useWatch({
    name: [
      'A.providerName',
      'B.providerName',
      'A.containerId',
      'B.containerId',
      'A.containerDisplayName',
      'B.containerDisplayName',
      'A.containerUrl',
      'B.containerUrl',
      'kind',
    ],
  });
  const isDraft = useIsFlowDraft();
  const { containerAError, containerBError } = useGetContainerErrors();
  const hasContainerErrors = containerAError || containerBError;
  const isLiteGuide = linkTypes.GUIDE_VARIANTS[linkKind] === linkTypes.GUIDE_VARIANT_TYPE.LITE;
  const isAddOnImprovements = useSelector((state) =>
    getFeatureFlagValue(state, featureTypes.FEATURES.ADD_ON_IMPROVEMENTS),
  );
  const isSyncStatusRevamp = useSelector((state) =>
    getFeatureFlagValue(state, featureTypes.FEATURES.SYNC_STATUS_REVAMP),
  );

  const showAddOnConfigMissing = useShouldShowWorkItemStepMissing();

  const providerIdentityAError = errors[pageName]?.A?.providerIdentityId?.error;
  const providerIdentityBError = errors[pageName]?.B?.providerIdentityId?.error;

  const showStepInErrorState =
    hasContainerErrors || providerIdentityAError || providerIdentityBError || showAddOnConfigMissing;

  let formattedErrorA = containerAError ? getTruncatedErrorMessage(containerAError) : null;
  if (!formattedErrorA && providerIdentityAError) {
    formattedErrorA = getTruncatedErrorMessage(providerIdentityAError);
  }

  let formattedErrorB = containerBError ? getTruncatedErrorMessage(containerBError) : null;
  if (!formattedErrorB && providerIdentityBError) {
    formattedErrorB = getTruncatedErrorMessage(providerIdentityBError);
  }

  const [providerIdentityA, providerIdentityB] = useGetProviderIdentities();
  const [labelFromAnomalies, statusFromAnomalies] = useGetLabelAndStatusFromAnomalies(linkId, PAGES.CONNECT_TOOLS);

  const providerIdentityIdA = providerIdentityA.get('_id');
  const providerIdentityIdB = providerIdentityB.get('_id');
  const [itemTypeA, itemTypeB] = useGetItemTypes();
  const [containerTypeA, containerTypeB] = useGetContainerTypes();
  const providerAExtraStep = useGetProviderExtraStep(providerNameA);
  const providerBExtraStep = useGetProviderExtraStep(providerNameB);

  // Container infos like displayName, url are now persisted in the link side directly (this was always the case
  // for active links but not for drafts), fallback on the infos from the fetched container if they are not present
  // in the link yet. This will enable the UI to keep displaying container names even in the case of errors.
  const [containerA, containerB] = useGetContainers();

  const containerNameA = containerDisplayNameA ?? containerA.get('displayName');
  const containerNameB = containerDisplayNameB ?? containerB.get('displayName');

  const containerAUrl = containerAUrlFromFlow ?? containerA.get('url');
  const containerBUrl = containerBUrlFromFlow ?? containerB.get('url');

  const shouldRenderLegacyAnomalies = !isSyncStatusRevamp && !!statusFromAnomalies;

  const containerAExtraConfigDetails = useHasMissingExtraStepAction(
    providerAExtraStep,
    providerNameA,
    providerIdentityIdA,
    containerIdA,
  );
  const containerBExtraConfigDetails = useHasMissingExtraStepAction(
    providerBExtraStep,
    providerNameB,
    providerIdentityIdB,
    containerIdB,
  );

  if (!providerNameA && !providerNameB) {
    return (
      <GuideStep
        status="current"
        actionLabel="Start here"
        actionSlug={routes.FLOW_BUILDER_PAGES.TOOL_SELECTION}
        statusLabel="Start here"
      >
        <GuideStepTitleWithTime title="1. Connect your tools" icon={ToolSelectionIcon} time={2} />
      </GuideStep>
    );
  }

  const status = getStepStatus({
    showStepInErrorState,
    isDraft,
    containerNameA,
    containerNameB,
    isSyncStatusRevamp,
    isLiteGuide,
    hasContainerErrors,
    statusFromAnomalies,
  });

  const isContainerASelected = !!containerIdA;
  const isContainerBSelected = !!containerIdB;

  const clickableContainerNameA = getClickableContainerName({
    containerName: containerNameA,
    containerUrl: containerAUrl,
    isContainerSelected: isContainerASelected,
    providerName: providerNameA,
    trackEvent,
    status,
  });

  const clickableContainerNameB = getClickableContainerName({
    containerName: containerNameB,
    containerUrl: containerBUrl,
    isContainerSelected: isContainerBSelected,
    providerName: providerNameB,
    trackEvent,
    status,
  });

  return (
    <GuideStep
      status={status}
      actionSlug={routes.FLOW_BUILDER_PAGES.TOOL_SELECTION}
      actionLabel={shouldRenderLegacyAnomalies ? labelFromAnomalies : getActionLabel(status)}
      statusLabel="Start here"
    >
      <Box flexDirection="column">
        <Box alignItems="center" m={[0, 0, tokens.spacing.s3, 0]}>
          <Box alignItems="center">
            <Box m={[0, tokens.spacing.s3, 0, 0]}>
              <StepIcon providerName={providerNameA} providerIdentity={providerIdentityA} />
            </Box>
            <Typography variant="body1">
              <GuideStepWorkItemLabel
                providerName={providerNameA}
                providerIdentity={providerIdentityA}
                containerName={clickableContainerNameA}
                containerExtraConfigDetails={!isAddOnImprovements && containerAExtraConfigDetails}
                containerError={formattedErrorA}
                itemType={itemTypeA}
                containerType={containerTypeA}
                containerId={containerIdA}
              />
            </Typography>
          </Box>
        </Box>
        <Box alignItems="center">
          <Box alignItems="center">
            <Box m={[0, tokens.spacing.s3, 0, 0]}>
              <StepIcon providerName={providerNameB} providerIdentity={providerIdentityB} />
            </Box>
            <Typography
              variant="body1"
              color={status === 'locked' ? tokens.colors.content.neutral.n20 : tokens.colors.content.neutral.n40}
            >
              <GuideStepWorkItemLabel
                providerName={providerNameB}
                providerIdentity={providerIdentityB}
                containerName={clickableContainerNameB}
                containerExtraConfigDetails={!isAddOnImprovements && containerBExtraConfigDetails}
                containerError={formattedErrorB}
                itemType={itemTypeB}
                containerType={containerTypeB}
                containerId={containerIdB}
              />
            </Typography>
          </Box>
        </Box>
      </Box>
    </GuideStep>
  );
};
